import React from "react";

const Photo = ({ src }) => (
  <div className="mb-4">
    <img
      src={src}
      alt="Advisor Photo"
      className="w-150 h-auto rounded-lg mx-auto mb-3"
    />
  </div>
);

export default Photo;
