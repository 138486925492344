import React, { useState } from "react";
import { Link } from "react-router-dom";
import logoImage from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";
import Menu from "../Menu.tsx";
import SecondMenu from "./SecondMenu.tsx";
import SearchBar from "../Searchbar.tsx";

const Header: React.FC = () => {
  const isAuthorized = (roles: number[]) => {
    return roles.includes(2001);
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-primary p-4 relative">
      <div
        className={`container mx-auto flex flex-wrap items-center justify-center md:justify-start  ${
          isAuthorized([2001, 1984, 5150]) ? "" : "flex-col justify-center"
        }`}
      >
        <div className="flex items-center relative">
          <Link to="/" className="text-white flex">
            <img
              src={logoImage}
              alt="logo"
              className="h-24 w-24"
              style={{ cursor: "pointer" }}
            />
            <h2 className="text-2xl text-accent font-semibold ml-4 self-center">
              BlueJay
            </h2>
          </Link>
          {isAuthorized([2001, 1984, 5150]) && (
            <button
              onClick={toggleNavbar}
              className="text-accent ml-8 md:hidden"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
            </button>
          )}
        </div>
        {isAuthorized([2001, 1984, 5150]) && (
          <div
            className={`${
              isOpen ? "block" : "hidden"
            } w-full md:flex md:items-center md:w-auto md:absolute p-4`}
            style={{ bottom: "-51px", right: "60px" }}
          >
            <div className="bg-secondary flex p-5">
              <Menu />
              <SearchBar />
            </div>
            <SecondMenu />
          </div>
        )}
      </div>
    </nav>
  );
};

export default Header;
