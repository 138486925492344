import React from "react";

const AddToFavoritesButton = ({ onClick, isLoggedIn }) => (
  <button
    className={`py-2 px-4 rounded ${
      isLoggedIn ? "bg-primary text-accent" : "bg-gray-400 text-gray-700"
    }`}
    onClick={onClick}
    disabled={!isLoggedIn}
  >
    Add to Favorites
  </button>
);

export default AddToFavoritesButton;
