import React, { useState } from "react";

const priceOptions = [
  { id: 1, label: "Low", symbol: "$" },
  { id: 2, label: "Medium Low", symbol: "$$" },
  { id: 3, label: "Medium High", symbol: "$$$" },
  { id: 4, label: "High", symbol: "$$$$" },
];

const PriceFilter: React.FC = () => {
  const [selectedPrice, setSelectedPrice] = useState<number | null>(null);

  const handleSelect = (id: number) => {
    setSelectedPrice(id);
  };

  return (
    <div className="price-filter p-4">
      <h3 className="text-lg font-semibold mb-2">Price</h3>
      <div className="flex space-x-2">
        {priceOptions.map((option) => (
          <button
            key={option.id}
            className={`px-4 py-2 rounded ${
              selectedPrice === option.id
                ? "bg-secondary text-accent"
                : "bg-primary text-gray-700"
            }`}
            onClick={() => handleSelect(option.id)}
          >
            {option.symbol}
          </button>
        ))}
      </div>
    </div>
  );
};

export default PriceFilter;
