import "./App.css";
import { Routes, Route } from "react-router-dom";
import Footer from "./components/Footer.tsx";
import Header from "./components/header/Header.tsx";
import Home from "./pages/Home.tsx";
import OurStory from "./pages/OurStory.tsx";
import HowItWorks from "./pages/HowItWorks.tsx";
import ShareAReview from "./pages/ShareAReview.tsx";
import Resources from "./pages/Resources.tsx";
import Login from "./components/user/Login.tsx";
import Contact from "./pages/Contact.tsx";
import ForgotPassword from "./components/user/ForgotPassword.tsx";
import ResetPassword from "./components/user/ResetPassword.tsx";
import ResetPasswordSuccess from "./components/user/ResetPasswordSuccess.tsx";
import CreateAccount from "./components/user/CreateAccount.tsx";
import MyAccount from "./pages/MyAccount.tsx";
import SearchResults from "./pages/SearchResults.tsx";
import Community from "./pages/Community.tsx";
import SeniorAdvisor from "./pages/Advisors.tsx";
import FAQ from "./components/faq/Faq.tsx";
import AdminLogin from "./pages/Admin.tsx";
import NotFound from "./pages/NotFound.tsx";
import ManageAdvisors from "./components/advisorList/ManageAdvisors.tsx";
import PersistLogin from "./components/user/PersistLogin.tsx";
import Unauthorized from "./pages/Unauthorized.tsx";

function App() {
  return (
    <>
      <Header />
      <Routes>
        {/* PUblic routes */}
        <Route path="/" element={<Home />} />
        <Route path="/search-results" element={<SearchResults />} />
        <Route path="/unauthorized" element={<Unauthorized />} />

        {/* Pages */}
        <Route path="/our-story" element={<OurStory />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="/share-a-review" element={<ShareAReview />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/contact" element={<Contact />} />

        {/* Dynamic Routes */}
        <Route path="/community/:dynamicRoute" element={<Community />} />
        <Route path="/senior-advisor/:advisorId" element={<SeniorAdvisor />} />
        <Route path="/faq" element={<FAQ />} />

        {/* user & admin */}
        <Route path="/admin" element={<AdminLogin />} />
        <Route path="/admin/manage-advisors" element={<ManageAdvisors />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/reset-password-success"
          element={<ResetPasswordSuccess />}
        />
        <Route path="/create-account" element={<CreateAccount />} />
        <Route path="/my-account" element={<MyAccount />} />

        {/* Catch-all route for 404 Not Found */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
