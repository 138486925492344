import React from "react";
import { Link } from "react-router-dom";

const Menu: React.FC = () => {
  return (
    <div className=" md:flex-grow md:flex md:items-center">
      <Link
        to="/"
        className="block mt-4 md:inline-block md:mt-0 text-accent mr-4 hover:underline"
      >
        Home
      </Link>
      <Link
        to="/our-story"
        className="block mt-4 md:inline-block md:mt-0 text-accent mr-4 hover:underline"
      >
        Our Story
      </Link>
      <Link
        to="/how-it-works"
        className="block mt-4 md:inline-block md:mt-0 text-accent mr-4 hover:underline"
      >
        How It Works
      </Link>
      <Link
        to="/share-a-review"
        className="block mt-4 md:inline-block md:mt-0 text-accent mr-4 hover:underline"
      >
        Share A Review
      </Link>
      <Link
        to="/resources"
        className="block mt-4 md:inline-block md:mt-0 text-accent mr-4 hover:underline"
      >
        Resources
      </Link>
    </div>
  );
};

export default Menu;
