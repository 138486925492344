import React from "react";
import ModalImage from "react-modal-image";

const CommunityFeatures = ({ features }) => (
  <div className="space-y-2">
    {features.category?.map((feature, index) => (
      <p key={index} className="inline text-lg font-semibold">
        {feature} |{" "}
      </p>
    ))}
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      {/* First Card: Image with Lightbox */}
      <div className="card">
        <ModalImage
          small={features.floorplanImg}
          large={features.floorplanImg}
          alt="Feature Image"
          className="w-full h-48 object-cover cursor-pointer"
        />
      </div>

      {/* Second Card: Room Info */}
      <div className="card p-4 bg-white shadow-md rounded-lg">
        <h2 className="text-xl font-semibold mb-2">Room Information</h2>
        <p>{features.roomInfo}</p>
      </div>

      {/* Third Card: Bathroom Info */}
      <div className="card p-4 bg-white shadow-md rounded-lg">
        <h2 className="text-xl font-semibold mb-2">Bathroom Information</h2>
        <p>{features.bathInfo}</p>
      </div>
    </div>
  </div>
);

export default CommunityFeatures;
