import React from "react";

const AreasServed = ({ areas }) => (
  <div className="mb-4">
    <h3 className="text-lg font-semibold mb-2">Areas Served</h3>
    <ul className="list-disc list-inside">
      {areas.map((area, index) => (
        <li key={index}>{area}</li>
      ))}
    </ul>
  </div>
);

export default AreasServed;
