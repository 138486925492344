import React from "react";

const HowItWorks: React.FC = () => {
  return (
    <>
      <div className="w-full overflow-hidden ">
        <div className="max-w-screen-3xl mx-auto ">
          <img
            src="https://via.placeholder.com/1920x600"
            alt="Hero"
            className="w-full h-auto"
          />
        </div>
      </div>
      <div className="container mx-auto p-4 min-h-screen">
        <h1 className="text-3xl font-bold mb-4">How It Works</h1>
        <h3 className="text-lg font-semibold">
          Welcome to Your Trusted Partner in Senior Living
        </h3>
        <p className="text-lg mt-3">
          Our dedicated senior advisors are here to assist you every step of the
          way. Whether you're interested in a specific community, need guidance
          on what happens next, or require a sounding board for your current
          situation, we are here to listen and provide support. We focus on
          finding the best community that meets your unique needs and
          preferences.
        </p>
        <p className="text-lg mt-3">
          What sets us apart is that our placement services are completely free.
          We do not charge you or collect fees from the communities we
          recommend, ensuring unbiased and honest assistance.
        </p>
        <p className="text-lg mt-3">
          Our senior advisors are also Senior Real Estate Specialists (SRES@).
          We understand the complexitites involved in managing your property
          during this transition. Our experienced Senior Advisors are here to
          help with all aspects of this process, providing invaluable support as
          you set up tours, explore communities, and make decisions at your own
          pace.
        </p>

        <p className="text-lg mt-3">
          Even if selling or leasing the home isn't on your immediate agenda,
          we're here for you. We aim to nurture our relationship and be a
          reliable resource for the future. When the time comes, you can count
          on us to guide you through every step, always with your best interests
          at heart.
        </p>
        <p className="text-lg mt-3">
          Our priority is to ensure you have all the information and resources
          you need to make the best decisions for yourself and your family. At
          the end of the day, it's all about what's best for you.
        </p>
      </div>
    </>
  );
};

export default HowItWorks;
