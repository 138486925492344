import React from "react";
import Faq from "../components/faq/Faq.tsx";

const Resources: React.FC = () => {
  return (
    <div className="container mx-auto p-4 min-h-screen">
      <h1 className="text-3xl font-bold mb-4">Welcome to the Resources Page</h1>
      <p className="text-lg mb-3">
        This is a mock page component. You can add more content here as needed.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat magnam,
        tenetur consequatur ipsum provident ducimus ipsam, error debitis fuga
        veniam perspiciatis rem illo! Quasi atque cum esse harum excepturi quae.
        Reiciendis ut nobis, laudantium iure consectetur assumenda nisi eveniet
        debitis molestias. Tenetur, expedita natus saepe odio impedit, nihil,
        harum provident fuga aut culpa voluptatum placeat sed molestiae modi non
        unde cupiditate ipsa explicabo quo magnam maxime. Fugiat nam vitae
        eligendi similique deleniti eaque necessitatibus accusantium sint
        assumenda quisquam eius, aperiam, voluptate sit error magnam non
        deserunt, culpa officiis omnis voluptas exercitationem aliquid
        consectetur nihil! Suscipit cum inventore quia sint enim!
      </p>
      <Faq />
    </div>
  );
};

export default Resources;
