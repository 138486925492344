import React from "react";

const AwardsRecognitions = ({ awards }) => (
  <div className="mb-4">
    <h3 className="text-lg font-semibold mb-2">Awards & Recognitions</h3>
    <ul className="list-disc list-inside">
      {awards.map((award, index) => (
        <li key={index}>{award}</li>
      ))}
    </ul>
  </div>
);

export default AwardsRecognitions;
