import React from "react";
import { useParams } from "react-router-dom";
import HeroImage from "../components/community/communityInfo/HeroImage.tsx";
import SubHeroImage from "../components/community/communityInfo/SubHeroImage.tsx";
import RequestTourButton from "../components/community/communityInfo/RequestTourButton.tsx";
import AdvisorInfo from "../components/community/communityInfo/AdvisorInfo.tsx";
import CommunityInfo from "../components/community/communityInfo/CommunityInfo.tsx";
import AddToFavoritesButton from "../components/community/communityInfo/AddToFavoritesButton.tsx";
import CommunityFeatures from "../components/community/communityInfo/CommunityFeatures.tsx";
import Map from "../components/map/Map.tsx";
import PricingTable from "../components/community/communityInfo/PricingTable.tsx";
import SectionHeader from "../components/community/communityInfo/SectionHeader.tsx";
import SectionContent from "../components/community/communityInfo/SectionContent.tsx";
const Community = () => {
  const { dynamicRoute } = useParams();

  // Example data
  const communityData = {
    heroImage: "https://via.placeholder.com/1920x1080",
    subHeroImages: [
      "https://via.placeholder.com/960x540",
      "https://via.placeholder.com/960x540",
      "https://via.placeholder.com/960x540",
      "https://via.placeholder.com/960x540",
    ],
    advisor: {
      name: "John Doe",
      title: "Community Manager",
      imageUrl: "https://via.placeholder.com/150",
    },
    name: "Community Name",
    address: "123 Community St, Los Angeles, CA, 90065",
    phone: "123-456-7890",
    license: "License #12345",
    features: {
      category: ["Memory Care", "Independent", "Assisted Living"], // this should correspond to category filter
      info: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Pariatur quis natus molestias dolorum veritatis praesentium sint reprehenderit eligendi vitae provident consequatur deleniti maxime ipsam iste labore fuga libero illum, consequuntur eos numquam ab impedit dolore quasi. Ducimus, nemo eos? Numquam iure laboriosam possimus exercitationem natus facere impedit culpa nihil, doloribus inventore, saepe repudiandae aliquid! Aut ab quod vero tempora doloribus.",
      floorplanImg: "https://via.placeholder.com/960x540",
      roomInfo:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam fuga esse distinctio earum, quaerat eligendi autem porro alias dolore incidunt.",
      roomBathInfo:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, fuga vitae modi non aliquid incidunt hic exercitationem a tempora expedita! Consequatur non ad itaque obcaecati? Sit ratione atque velit saepe?",
    },
    units: [
      {
        number: "101",
        rate: "$2000",
        availability: "Available",
        info: "Info 1",
      },
      {
        number: "102",
        rate: "$2500",
        availability: "Not Available",
        info: "Info 2",
      },
    ],
    sections: {
      CommunityDetails: "Community details content...",
      CareInformation: "Care information content...",
      Activities: "Activities content...",
      FoodAndDining: "Food and dining content...",
      Amenities: "Amenities content...",
    },
  };

  return (
    <div className="space-y-8 container">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="h-full">
          <HeroImage src={communityData.heroImage} alt="Hero Image" />
        </div>
        <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-2 h-full">
          {communityData.subHeroImages.map((src, index) => (
            <SubHeroImage
              key={index}
              src={src}
              alt={`Sub Hero Image ${index + 1}`}
            />
          ))}
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
        <div className="md:col-span-2">
          <CommunityInfo
            name={communityData.name}
            address={communityData.address}
            phone={communityData.phone}
            license={communityData.license}
          />
          <AddToFavoritesButton
            onClick={() => alert("Added to Favorites")}
            isLoggedIn={true}
          />
          <CommunityFeatures features={communityData.features} />
        </div>
        <div className="md:col-span-1 space-y-3">
          <AdvisorInfo {...communityData.advisor} />
          <RequestTourButton onClick={() => alert("Request a Tour")} />
        </div>
      </div>

      <PricingTable units={communityData.units} />
      <div className="flex flex-col md:flex-row">
        <div className="md:w-2/3">
          {Object.entries(communityData.sections).map(([key, content]) => (
            <div key={key} className="border-2">
              <SectionHeader title={key.replace(/([A-Z])/g, " $1").trim()} />
              <SectionContent content={content} />
            </div>
          ))}
        </div>
        <div className="md:w-1/3 w-full h-auto">
          {/* <Map location={communityData.address} nearbyProperties={[]} /> */}
          <Map
            property={{
              id: "mock-id",
              name: "Mock Property",
              latitude: 47.6062, // Example latitude
              longitude: -122.3321, // Example longitude
              summary: "This is a mock property for testing purposes.",
            }}
            nearbyProperties={[
              {
                id: "nearby-1",
                name: "Nearby Property 1",
                latitude: 47.6072,
                longitude: -122.3325,
                summary: "This is a nearby property 1.",
              },
              {
                id: "nearby-2",
                name: "Nearby Property 2",
                latitude: 47.6082,
                longitude: -122.3335,
                summary: "This is a nearby property 2.",
              },
              {
                id: "nearby-3",
                name: "Nearby Property 3",
                latitude: 47.6092,
                longitude: -122.3345,
                summary: "This is a nearby property 3.",
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default Community;
