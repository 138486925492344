import React, { useEffect, useRef, useState } from "react";
import { MapProps } from "../../types/types";
// Extend the Window interface to include Microsoft
declare global {
  interface Window {
    Microsoft: typeof Microsoft;
    bingMapsCallback: () => void;
  }
}

// Declare the Microsoft namespace globally
declare namespace Microsoft {
  namespace Maps {
    class Map {
      constructor(element: HTMLElement, options: any);
      entities: {
        push(entity: any): void;
        remove(entity: any): void;
      };
      static getMap(element: HTMLElement): Map;
    }
    class Location {
      constructor(latitude: number, longitude: number);
    }
    class Pushpin {
      constructor(location: Location, options?: any);
      setOptions(options: any): void;
      getTitle(): string;
    }
    namespace Events {
      function addHandler(
        target: any,
        eventName: string,
        handler: (e: any) => void
      ): void;
    }
  }
}

const Map: React.FC<MapProps> = ({
  property,
  nearbyProperties,
  onMapReady,
}) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<Microsoft.Maps.Map | null>(null);
  const [pushpins, setPushpins] = useState<Microsoft.Maps.Pushpin[]>([]);
  const apiKey = process.env.REACT_APP_BING_MAPS_API_KEY;
  const scriptLoadedRef = useRef(false);

  useEffect(() => {
    if (scriptLoadedRef.current) return;

    const existingScript = document.querySelector(
      `script[src*="bing.com/api/maps/mapcontrol"]`
    );

    if (existingScript) {
      if (window.Microsoft && window.Microsoft.Maps) {
        callback();
      } else {
        existingScript.addEventListener("load", callback);
      }
    } else {
      loadBingMapsScript(apiKey!, callback);
    }

    scriptLoadedRef.current = true;

    function callback() {
      if (window.Microsoft && window.Microsoft.Maps && mapRef.current) {
        const newMap = new window.Microsoft.Maps.Map(mapRef.current, {
          credentials: apiKey,
          center: new window.Microsoft.Maps.Location(
            property.latitude,
            property.longitude
          ),
          zoom: 14,
        });

        setMap(newMap);

        if (onMapReady) {
          onMapReady();
        }
      }
    }
  }, [apiKey, property, onMapReady]);

  useEffect(() => {
    if (map) {
      // Add pushpin for the main property
      const mainPushpin = new window.Microsoft.Maps.Pushpin(
        new window.Microsoft.Maps.Location(
          property.latitude,
          property.longitude
        ),
        { title: property.name }
      );
      map.entities.push(mainPushpin);
      setPushpins((prevPushpins) => [...prevPushpins, mainPushpin]);

      // Add pushpins for nearby properties
      nearbyProperties.forEach((nearbyProperty) => {
        const pushpin = new window.Microsoft.Maps.Pushpin(
          new window.Microsoft.Maps.Location(
            nearbyProperty.latitude,
            nearbyProperty.longitude
          ),
          { title: nearbyProperty.name }
        );
        map.entities.push(pushpin);
        setPushpins((prevPushpins) => [...prevPushpins, pushpin]);
      });

      // Add click event listener to the map
      window.Microsoft.Maps.Events.addHandler(map, "click", (e) => {
        if (e.location) {
          const clickPushpin = new window.Microsoft.Maps.Pushpin(e.location, {
            title: "New Pushpin",
          });
          map.entities.push(clickPushpin);
          setPushpins((prevPushpins) => [...prevPushpins, clickPushpin]);
        }
      });
    }
  }, [map, property, nearbyProperties]);

  const removePushpin = (pushpin: Microsoft.Maps.Pushpin) => {
    if (map) {
      map.entities.remove(pushpin);
      setPushpins((prevPushpins) => prevPushpins.filter((p) => p !== pushpin));
    }
  };

  const renamePushpin = (pushpin: Microsoft.Maps.Pushpin, newName: string) => {
    pushpin.setOptions({ title: newName });
  };

  return (
    <div>
      <div ref={mapRef} style={{ width: "100%", height: "400px" }} />
      {map && (
        <div>
          {pushpins.map((pushpin, index) => (
            <div key={index}>
              <span>{pushpin.getTitle()}</span>
              <button onClick={() => removePushpin(pushpin)}>Remove</button>
              <button
                onClick={() =>
                  renamePushpin(pushpin, `Renamed Pushpin ${index + 1}`)
                }
              >
                Rename
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Map;

function loadBingMapsScript(apiKey: string, callback: () => void) {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.async = true;
  script.defer = true;
  script.src = `https://www.bing.com/api/maps/mapcontrol?callback=bingMapsCallback&key=${apiKey}`;
  document.body.appendChild(script);

  window.bingMapsCallback = callback;
}
