import React from "react";
import CommunityDetails from "../components/community/CommunityDetails.tsx";
import CommunityReviews from "../components/community/CommunityReviews.tsx";
import FilterComponent from "../components/filters/FilterComponent.tsx";
import Callout from "../components/Callout.tsx";

const SearchResults: React.FC = () => {
  const communityData = {
    imageUrl: "https://via.placeholder.com/400",
    communityDetailsUrl: "/community-details",
    title: "Community Name",
    contactName: "John Doe",
    contactTitle: "Community Manager",
    messageUrl: "/community-details/message",
    email: "contact@community.com",
    address: "123 Community St, Los Angeles, CA, 90065",
    reviews: [
      { id: 1, name: "John Doe", content: "Great community!", rating: 8.4 },
      {
        id: 2,
        name: "Jill Smith",
        content: "Very friendly people.",
        rating: 9.2,
      },
    ],
  };
  const calloutData = {
    title:
      "Discover Senior Care Solutions with Zero Costs and Zero Referral Fees!",
    content:
      "Search for the perfect community absolutely free for you and the community. We're dedicated to providing cost-free introductions to the best senior care options available. As the first and only senior care website with no affiliations, we ensure you're connected directly to the finest communities that meet your needs, with no hidden fees or conflicts of interest.",
  };

  return (
    <>
      <Callout title={calloutData.title} content={calloutData.content} />
      <h1 className="text-xxl font-bold mt-8">Search Results</h1>
      <div className="flex flex-col sm:flex-row search-results space-y-8">
        <FilterComponent />
        <CommunityDetails
          imageUrl={communityData.imageUrl}
          communityDetailsUrl={communityData.communityDetailsUrl}
          title={communityData.title}
          contactName={communityData.contactName}
          contactTitle={communityData.contactTitle}
          messageUrl={communityData.messageUrl}
          email={communityData.email}
          address={communityData.address}
        />
        <CommunityReviews reviews={communityData.reviews} />
      </div>
    </>
  );
};

export default SearchResults;
