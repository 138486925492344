import React from "react";

interface Review {
  id: number;
  content: string;
  name: string;
  rating: number;
}

interface CommunityReviewsProps {
  reviews: Review[];
}

const CommunityReviews: React.FC<CommunityReviewsProps> = ({ reviews }) => {
  return (
    <div className="space-y-10 mx-auto">
      <h2 className="text-lg font-bold text-secondary">Reviews from the Web</h2>
      {reviews.map((review) => (
        <div key={review.id} className="p-4 border rounded shadow-sm">
          <h2 className="text-base font-bold text-secondary">{review.name}</h2>
          <p className="text-gray-700">{review.rating} / 10</p>
          <p className="text-gray-700">{review.content}</p>
        </div>
      ))}
    </div>
  );
};

export default CommunityReviews;
