import React from "react";
import { Link } from "react-router-dom";

const SecondMenu: React.FC = () => {
  return (
    <div
      className="md:flex-grow md:absolute"
      style={{ top: "-15px", right: "0" }}
    >
      <Link
        to="/login"
        className="block mt-4 md:inline-block md:mt-0 text-accent mr-4 hover:underline"
      >
        Login
      </Link>
      <span className="hidden text-accent md:inline">|</span>
      <Link
        to="/contact"
        className="block mt-4 md:inline-block md:mt-0 text-accent md:mx-4 hover:underline"
      >
        Contact Us
      </Link>
    </div>
  );
};

export default SecondMenu;
