import React from "react";

interface CommunityDetailsProps {
  imageUrl: string;
  communityDetailsUrl: string;
  title: string;
  contactName: string;
  contactTitle: string;
  messageUrl: string;
  email: string;
  address: string;
}

const CommunityDetails: React.FC<CommunityDetailsProps> = ({
  imageUrl,
  communityDetailsUrl,
  title,
  contactName,
  contactTitle,
  messageUrl,
  email,
  address,
}) => {
  return (
    <div className="flex community-details p-4 border rounded shadow-sm space-y-4">
      {/* Community Image */}
      <a href={communityDetailsUrl}>
        <img
          src={imageUrl}
          alt="Community"
          className="max-w-24 h-auto rounded"
        />
      </a>

      <div className="flex flex-col m-4">
        {/* Community Title */}
        <a
          href={communityDetailsUrl}
          className="text-xl font-bold text-secondary hover:underline mb-2"
        >
          {title}
        </a>

        {/* Community Contact */}
        <a
          href={communityDetailsUrl}
          className="text-lg text-primary hover:underline mb-3"
        >
          {contactName}, {contactTitle}
        </a>

        {/* Message and Email Links */}
        <div className="flex space-x-4">
          <a href={messageUrl} className="text-secondary hover:underline mb-3">
            Send Message
          </a>
          <a
            href={`mailto:${email}`}
            className="text-secondary hover:underline mb-3"
          >
            Send Email
          </a>
        </div>

        {/* Community Address */}
        <p className="text-primary">{address}</p>

        {/* Community Link */}
        <a
          href={communityDetailsUrl}
          className="text-secondary hover:underline mb-3"
        >
          View Community Details
        </a>
      </div>
    </div>
  );
};

export default CommunityDetails;
