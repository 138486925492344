import React from "react";
import { Link } from "react-router-dom"; // Assuming you're using react-router for navigation

interface BreadcrumbsProps {
  items: { label: string; path: string }[];
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ items }) => {
  return (
    <nav className="text-sm mb-4">
      <ol className="list-reset flex text-gray-700">
        {items.map((item, index) => (
          <li key={index} className="flex items-center">
            {index > 0 && <span className="mx-2">/</span>}
            <Link to={item.path} className="hover:underline">
              {item.label}
            </Link>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
