import React from "react";

const OurStory: React.FC = () => {
  return (
    <>
      <div className="w-full overflow-hidden">
        <div className="max-w-screen-3xl mx-auto ">
          <img
            src="https://via.placeholder.com/1920x600"
            alt="Hero"
            className="w-full h-auto"
          />
        </div>
      </div>
      <div className="container mx-auto p-4 min-h-screen">
        <h1 className="text-3xl font-bold mb-4">Our Story</h1>
        <p className="text-lg">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium
          iste vitae repudiandae animi soluta! Cupiditate, nihil, cum laborum a
          veritatis voluptatum nisi culpa ex sapiente nostrum molestias
          consectetur omnis adipisci voluptatibus et quo rerum at velit nemo
          totam vel. Voluptatibus!
        </p>
      </div>
    </>
  );
};

export default OurStory;
