import React from "react";
import SearchBar from "../components/Searchbar.tsx";

const Home: React.FC = () => {
  return (
    <div>
      <div className="w-full overflow-hidden">
        <div className="max-w-screen-3xl mx-auto ">
          <img
            src="https://via.placeholder.com/1920x1080"
            alt="Hero"
            className="w-full h-auto"
          />
        </div>
      </div>
      <div className="container mx-auto p-4 min-h-screen">
        <div className="w-96 mx-auto border-2 p-3 rounded-xl my-4">
          <SearchBar />
        </div>
        <h1 className="text-3xl font-bold mb-4">Welcome to the Home Page</h1>
        <p className="text-lg">
          This is a mock home page component. You can add more content here as
          needed.
        </p>
      </div>
    </div>
  );
};

export default Home;
