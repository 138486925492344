// Can use this component to edit/add an advisor's information.

import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Advisor, EditAdvisorPopupProps } from "../../types/types";

const EditAdvisorPopup: React.FC<EditAdvisorPopupProps> = ({
  advisor,
  isOpen,
  onClose,
  onSave,
}) => {
  const [formData, setFormData] = useState<Advisor>({
    name: "",
    title: "",
    email: "",
    phone: "",
    direct: "",
    fax: "",
    advisorImage: "",
    bio: "",
    instagram: "",
    facebook: "",
    linkedin: "",
    yearsInBusiness: "",
    location: "",
    credentials: [],
    servicesOffered: [],
    awardsRecognitions: [],
    areasServed: [],
  });

  const [newCredential, setNewCredential] = useState<string>("");
  const [newService, setNewService] = useState<string>("");
  const [newAward, setNewAward] = useState<string>("");
  const [newArea, setNewArea] = useState<string>("");

  // uncomment once the advisor prop is passed in; right now, advisor doesn't contain the same fields.
  // useEffect(() => {
  //   if (advisor) {
  //     console.log("advisor exists", advisor);
  //     setFormData(advisor);
  //   }
  // }, [advisor]);

  const handleBioChange = (value: string) => {
    setFormData({ ...formData, bio: value });
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(formData);
    onClose();
  };

  const handleArrayChange = (name: string, value: string) => {
    console.log("name", name);
    console.log("value", value);

    setFormData({ ...formData, [name]: [...formData[name], value] });
  };

  const handleArrayRemove = (name: string, index: number) => {
    const newArray = formData[name].filter((_, i) => i !== index);
    setFormData({ ...formData, [name]: newArray });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-xl h-3/4 overflow-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Edit Advisor</h2>
          <button
            className="text-gray-500 hover:text-gray-700"
            onClick={onClose}
          >
            &times;
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block mb-1">Full Name</label>
            <input
              type="text"
              name="name"
              className="w-full p-2 border rounded"
              value={formData.name}
              onChange={handleChange}
              pattern="^[a-zA-Z][a-zA-Z\s,.'-]*$"
              maxLength={70}
              required
              title="Name must start with a letter and may only contain the letters a-z, spaces, and the following characters: ,.'-"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1">Title</label>
            <input
              type="text"
              name="title"
              className="w-full p-2 border rounded"
              value={formData.title}
              onChange={handleChange}
              pattern="^[a-zA-Z][a-zA-Z\s,.'-]*$"
              maxLength={200}
              required
              title="Title must start with a letter and may only contain the letters a-z, spaces, and the following characters: ,.'-"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1">Email</label>
            <input
              type="email"
              name="email"
              className="w-full p-2 border rounded"
              value={formData.email}
              onChange={handleChange}
              maxLength={255}
              required
              title="Please enter a valid email address"
            />
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            <div className="mb-4">
              <label className="block mb-1">Phone</label>
              <input
                type="text"
                name="phone"
                className="w-full p-2 border rounded"
                value={formData.phone}
                onChange={handleChange}
                pattern="\d{10}"
                maxLength={10}
                required
                title="Phone number must be 10 digits"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1">Direct</label>
              <input
                type="text"
                name="direct"
                className="w-full p-2 border rounded"
                value={formData.direct}
                onChange={handleChange}
                pattern="\d{10}"
                maxLength={10}
                title="Direct number must be 10 digits"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1">Fax</label>
              <input
                type="text"
                name="fax"
                className="w-full p-2 border rounded"
                value={formData.fax}
                onChange={handleChange}
                pattern="\d{10}"
                maxLength={10}
                title="Fax number must be 10 digits"
              />
            </div>
          </div>
          <div className="mb-4">
            <label className="block mb-1">Advisor Image</label>
            <input
              type="file"
              name="advisorImage"
              className="w-full p-2 border rounded"
              accept=".jpg,.png,.gif"
              required
              title="File must be .jpg, .png, or .gif format"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1">Advisor Bio</label>
            <ReactQuill
              value={formData.bio}
              onChange={handleBioChange}
              className="w-full p-2 border rounded h-"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1">Instagram Profile URL</label>
            <input
              type="url"
              name="instagram"
              className="w-full p-2 border rounded"
              value={formData.instagram}
              onChange={handleChange}
              pattern="https?://.+"
              title="Please enter a valid URL"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1">Facebook Profile URL</label>
            <input
              type="url"
              name="facebook"
              className="w-full p-2 border rounded"
              value={formData.facebook}
              onChange={handleChange}
              pattern="https?://.+"
              title="Please enter a valid URL"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1">LinkedIn Profile URL</label>
            <input
              type="url"
              name="linkedin"
              className="w-full p-2 border rounded"
              value={formData.linkedin}
              onChange={handleChange}
              pattern="https?://.+"
              title="Please enter a valid URL"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1">Years in Business</label>
            <input
              type="text"
              name="yearsInBusiness"
              className="w-full p-2 border rounded"
              value={formData.yearsInBusiness}
              onChange={handleChange}
              pattern="\d{1,2}"
              maxLength={2}
              required
              title="Years in Business must be 1-2 digits"
            />
          </div>

          {/*  */}

          {/* Credentials */}
          <div className="mb-4">
            <label className="block mb-1">Credentials</label>
            <ul>
              {formData.credentials.map((credential, index) => (
                <li
                  key={index}
                  className="flex justify-between items-center mb-1"
                >
                  <span>{credential}</span>
                  <button
                    type="button"
                    className="ml-2 px-2 py-1 bg-primary hover:bg-primary-dark text-white rounded "
                    onClick={() => handleArrayRemove("credentials", index)}
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>
            <div className="flex mb-2">
              <input
                type="text"
                name="newCredential"
                className="w-full p-2 border rounded"
                placeholder="Add a credential"
                value={newCredential}
                onChange={(e) => setNewCredential(e.target.value)}
              />
              <button
                type="button"
                className="ml-2 px-4 py-2 bg-secondary text-white rounded hover:bg-secondary-dark"
                onClick={() => handleArrayChange("credentials", newCredential)}
              >
                Add
              </button>
            </div>
          </div>

          {/* Services Offered */}
          <div className="mb-4">
            <label className="block mb-1">Services Offered</label>
            <ul>
              {formData.servicesOffered.map((service, index) => (
                <li
                  key={index}
                  className="flex justify-between items-center mb-1"
                >
                  <span>{service}</span>
                  <button
                    type="button"
                    className="ml-2 px-2 py-1 bg-primary hover:bg-primary-dark text-white rounded "
                    onClick={() => handleArrayRemove("servicesOffered", index)}
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>
            <div className="flex mb-2">
              <input
                type="text"
                name="newService"
                className="w-full p-2 border rounded"
                placeholder="Add a service"
                value={newService}
                onChange={(e) => setNewService(e.target.value)}
              />
              <button
                type="button"
                className="ml-2 px-4 py-2 bg-secondary text-white rounded hover:bg-secondary-dark"
                onClick={() => handleArrayChange("servicesOffered", newService)}
              >
                Add
              </button>
            </div>
          </div>

          {/* Awards & Recognitions */}
          <div className="mb-4">
            <label className="block mb-1">Awards & Recognitions</label>

            <ul>
              {formData.awardsRecognitions.map((award, index) => (
                <li
                  key={index}
                  className="flex justify-between items-center mb-1"
                >
                  <span>{award}</span>
                  <button
                    type="button"
                    className="bg-primary hover:bg-primary-dark ml-2 px-2 py-1  text-white rounded "
                    onClick={() =>
                      handleArrayRemove("awardsRecognitions", index)
                    }
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>
            <div className="flex mb-2">
              <input
                type="text"
                name="newAward"
                className="w-full p-2 border rounded"
                placeholder="Add an award"
                value={newAward}
                onChange={(e) => setNewAward(e.target.value)}
              />
              <button
                type="button"
                className="ml-2 px-4 py-2 bg-secondary text-white rounded hover:bg-secondary-dark"
                onClick={() =>
                  handleArrayChange("awardsRecognitions", newAward)
                }
              >
                Add
              </button>
            </div>
          </div>

          {/* Areas Served */}
          <div className="mb-4">
            <label className="block mb-1">Areas Served</label>
            <ul>
              {formData.areasServed.map((area, index) => (
                <li
                  key={index}
                  className="flex justify-between items-center mb-1"
                >
                  <span>{area}</span>
                  <button
                    type="button"
                    className="ml-2 px-2 py-1 bg-primary hover:bg-primary-dark text-white rounded "
                    onClick={() => handleArrayRemove("areasServed", index)}
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>
            <div className="flex mb-2">
              <input
                type="text"
                name="newArea"
                className="w-full p-2 border rounded"
                placeholder="Add an area"
                value={newArea}
                onChange={(e) => setNewArea(e.target.value)}
              />
              <button
                type="button"
                className="ml-2 px-4 py-2 bg-secondary text-white rounded hover:bg-secondary-dark"
                onClick={() => handleArrayChange("areasServed", newArea)}
              >
                Add
              </button>
            </div>
          </div>

          {/*  */}
          <div className="flex justify-between">
            <button
              type="submit"
              className="self-start px-4 py-2 bg-chocolate_cosmos text-accent rounded hover:bg-red-700"
            >
              Delete
            </button>
            <div>
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-primary text-accent rounded hover:bg-secondary"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditAdvisorPopup;
