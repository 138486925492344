import React from "react";

const CommunityInfo = ({ name, address, phone, license }) => (
  <div className="space-y-2">
    <h1 className="text-3xl font-bold">{name}</h1>
    <p className="text-lg">{address}</p>
    <p className="text-lg">
      <a href={`tel:${phone}`} className="text-primary font-semibold">
        {phone}
      </a>
    </p>
    <p className="text-sm text-secondary">State License: {license}</p>
  </div>
);

export default CommunityInfo;
