import React from "react";
// import logoImage from "../assets/logo-footer.png";
// import pgaSVG from "../../public/logo192.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <footer className="pt-3 bg-primary text-accent">
      <div className="container mx-auto">
        <div className="flex flex-wrap ">
          <div className="w-full md:w-1/4 flex justify-center mb-4 md:mb-0">
            {/* <img src={logoImage} alt="Company Logo" className="h-16" /> */}
          </div>
          <div className="w-full md:w-1/4 mb-4 md:mb-0">
            {/* <h5 className="text-lg font-semibold mb-2">Links</h5> */}
            <ul className="list-none">
              <li className="mb-1">
                <a href="/our-story" className="hover:underline">
                  Our Story
                </a>
              </li>
              <li className="mb-1">
                <a href="/how-it-works" className="hover:underline">
                  How it Works
                </a>
              </li>
              <li className="mb-1">
                <a href="/share-a-review" className="hover:underline">
                  Share A Review
                </a>
              </li>
              <li className="mb-1">
                <a href="/resources" className="hover:underline">
                  Resources
                </a>
              </li>
            </ul>
          </div>
          <div className="w-full md:w-1/4 mb-4 md:mb-0">
            {/* <h5 className="text-lg font-semibold mb-2">Follow Us</h5> */}
            <ul className="list-none flex space-x-4">
              <li className="mb-1">
                <a
                  href="https://www.facebook.com/yourcompany"
                  className="hover:underline text-accent group"
                >
                  <FontAwesomeIcon
                    icon={faFacebook}
                    className="text-accent group-hover:text-secondary"
                  />
                </a>
              </li>
              <li className="mb-1">
                <a
                  href="https://www.twitter.com/yourcompany"
                  className="hover:underline text-accent group"
                >
                  <FontAwesomeIcon
                    icon={faTwitter}
                    className="text-accent group-hover:text-secondary"
                  />
                </a>
              </li>
              <li className="mb-1">
                <a
                  href="https://www.youtube.com/yourcompany"
                  className="hover:underline text-accent group"
                >
                  <FontAwesomeIcon
                    icon={faYoutube}
                    className="text-accent group-hover:text-secondary"
                  />
                </a>
              </li>
            </ul>
          </div>
          <div className="w-full md:w-1/4 flex justify-center">
            {/* <img src={pgaSVG} alt="PGA Logo" className="h-16" /> */}
          </div>
        </div>
      </div>

      <div className="w-full bg-secondary overflow-x-hidden">
        <div className="max-w-screen-xl mx-auto container flex flex-col md:flex-row justify-around">
          <div className="text-center py-3">
            <a href="/" className="hover:underline mr-2">
              Privacy
            </a>{" "}
            |
            <a href="/" className="hover:underline ml-2">
              Terms
            </a>
          </div>
          <div className="text-center py-3">
            © 2024 Bluejay, all rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
