import React from "react";
import CategoryFilter from "./CategoryFilter.tsx";
import PriceFilter from "./PriceFilter.tsx";

const FilterComponent: React.FC = () => {
  return (
    <div className="border-primary border-2">
      <h2 className="text-xxl font-semibold m-5 p-5">Filter Options</h2>
      <PriceFilter />
      <CategoryFilter />
    </div>
  );
};

export default FilterComponent;
