import React, { useState } from "react";

const SearchBar: React.FC = () => {
  const [query, setQuery] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const handleSearch = () => {
    // Implement your search logic here
    console.log("Searching for:", query);
  };

  return (
    <div className=" items-center  border-b-2 border-accent py-2 ml-8 hidden md:flex">
      <input
        className="appearance-none bg-transparent border-none w-full text-accent mr-3 py-1 px-2 leading-tight focus:outline-none"
        type="text"
        placeholder="Search..."
        aria-label="Search"
        value={query}
        onChange={handleInputChange}
      />
      <button
        className="flex-shrink-0 bg-primary hover:bg-tertiary border-accent hover:border-secondary text-sm border-4 text-accent py-1 px-2 rounded"
        type="button"
        onClick={handleSearch}
      >
        Search
      </button>
    </div>
  );
};

export default SearchBar;
