import React from "react";
import { useState } from "react";
interface CalloutProps {
  title: string;
  content: string;
}

const Callout: React.FC<CalloutProps> = ({ title, content }) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }
  return (
    <div className="bg-blue-100 w-full py-8 px-4 relative">
      <button
        className="absolute top-2 right-2 text-blue-900 font-bold"
        onClick={handleClose}
      >
        &times;
      </button>
      <h2 className="text-2xl font-bold text-center text-blue-900">{title}</h2>
      <p className="mt-4 text-center text-blue-800">{content}</p>
    </div>
  );
};

export default Callout;
